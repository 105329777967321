
import VisionAR from './vision'
import { db, ref, get, child } from '../firebase'
import { useState } from 'react'
import FlappyOnScreenInstructions from '../Canvas/Flappy/FlappyOnScreenInstructions'
import CanvasManager from '../Canvas/CanvasManager'
/* import fish from '../../assets/imgTarget.mind' */
import '../../modes/webxr_immersive/immersive.css'

export default function ARexperienceManager({
    username, profileHash, profileSnapshot,

    aframeComponentsInitialized, setAframeComponentsInitialized,

    GameState, setGameState, jumping,

    canvasInteraction, sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode }) {


    return (
        <>
            <a-scene ref={sceneRef}
                class="clickable"
                background="color: #ECECEC"
                 webxr="requiredFeatures: image-tracking,local-floor; optionalFeatures: dom-overlay; overlayElement: #overlay"
                color-space="sRGB"
            
                vr-mode-ui="enabled: true"
                //device-orientation-permission-ui="enabled: false"
                >

                <a-assets id="assets">
                        <img
                        id="earth"
                        src="https://reality-reach-assets.storage.googleapis.com/vision/general-assets/BinaryMarker.jpg"
                        />
                </a-assets>

                <a-entity xr-tracked-images> </a-entity>
                <a-entity //mindar-image-target="targetIndex: 0" "https://cdn.glitch.com/170bb543-c9df-448f-aced-de914aeedbba%2Farcore-sample-000-earth.jpg?v=1592958053263"
                    xr-tracked-image="element:#earth; widthInMeters: 0.095">

                    <a-plane
                            rotation="-90 0 0"
                            width="0.095"
                            height="0.095"
                            color="#7BC8A4"
                            opacity="0.5"
                            >
                            </a-plane>
                            <a-sphere
                            position="0 0.03 0"
                            radius="0.03"
                            color="#0000ff"
                            opacity="0.7"
                            ></a-sphere>

                    {profileHash ? (
                        <>
                            <a-entity rotation = "-90 0 0" scale="0.1 0.1 0.1" >
                                <VisionAR {...{
                                    profileHash, profileSnapshot,
                                    uiVisible, setMode
                                }} />
                            </a-entity>
                            <a-entity rotation = "-90 0 0" scale="0.1 0.1 0.1" >
                                <CanvasManager {...{
                                    username, profileHash, profileSnapshot,

                                    aframeComponentsInitialized, setAframeComponentsInitialized,

                                    GameState, setGameState, jumping,

                                    canvasInteraction, sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode
                                }} />
                            </a-entity>
                            

                            {/* <InkTeract /> */}
                        </>
                    )
                        :
                        (<>
                        </>)}
                </a-entity>
            </a-scene>
        </>
    )
}