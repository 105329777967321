import Flappy from "./Flappy/flappy";



export default function CanvasManager({
    username, profileHash, profileSnapshot,

    aframeComponentsInitialized, setAframeComponentsInitialized,

    GameState, setGameState, jumping,

    canvasInteraction, sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode }) {
    return canvasInteraction == "Flappy" ? (<Flappy {...{
        username, profileSnapshot,
        aframeComponentsInitialized, setAframeComponentsInitialized,
        uiVisible, setUiVisible,
        jumping,
        GameState, setGameState

    }} />) : null
}