import { Typography, Stack } from "@mui/material";
import { useRef } from "react";
import styles from './flappy.module.css'
import buttonStyles from '../../ComponentStyles/buttonStyles.module.css'

export default function FlappyOnScreenInstructions({ GameState,
    setGameState, EmitGameEndEvents }) {

    ///Game Assets
    var GameUIback = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/gamification/GameUIback.png'


    function TextPlayInstructions() {
        return GameState.inGame && !GameState.Over ? (
            <Typography sx={{
                color: 'white',
                fontSize: 20,
                width: '100%',
                fontFamily: 'Changa',
                justifyContent: 'center',
                alignItems: 'center',
                top: '40vh',
                zIndex: 9
            }}
                className={styles.fadeInOut}>
                Tap anywhere on the screen
            </Typography>) : null
    }

    function GameOverInstructions() {
        return GameState.Over && GameState.inGame
            && !GameState.HighScore ?
            (<>
                <img

                    style={{ top: '10%' }}
                    onClick={() => {
                        /* Website(url) */
                    }}
                    width='483px'
                    height='483px'
                    /*  width='80%' */
                    src="https://reality-reach-assets.storage.googleapis.com/ar-card/images/gamification/flappy/character.png"
                />
                <Typography sx={{
                    color: 'white',
                    fontSize: 20,
                    fontFamily: 'Changa',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '40vh',
                    zIndex: 9
                }}
                    className={styles.fadeInOut}>
                    Game Over! <br></br>
                    Tap anywhere to play again
                </Typography>
            </>) : null
    }

    function HighScoreInstructions() {
        return GameState.Over && GameState.inGame
            && GameState.HighScore ?
            (<>
                <img

                    style={{ top: '10%' }}
                    onClick={() => {
                        /* Website(url) */
                    }}
                    width='483px'
                    height='483px'
                    /*  width='80%' */
                    src="https://reality-reach-assets.storage.googleapis.com/ar-card/images/gamification/flappy/character.png"
                />
                <Typography sx={{
                    color: 'white',
                    fontSize: 20,
                    fontFamily: 'Changa',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '40vh',
                    zIndex: 9
                }}
                    className={styles.highScoreText}>
                    High Score! <br></br>
                    This score will be now be attached to the profile 
                    <br></br>
                    <br></br>
                    Tap to play again
                </Typography>
            </>) : null
    }

    return (
        <>
            <TextPlayInstructions />
            <GameOverInstructions />
            <HighScoreInstructions />
            {GameState.inGame ?
                (
                    <div style={{ justifyContent: 'center', width: '100%' }}>
                        <img
                            style={{ top: '90vh', right: '10vw' }}
                            className={buttonStyles.button}
                            onClick={() => {
                                EmitGameEndEvents()
                            }}
                            height='10%'
                            /* width='100px' */
                            src={GameUIback} />
                    </div>
                )
                :
                (<></>)}
        </>
    )
}