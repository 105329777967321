
import axios from 'axios'
import {
    Dialog, DialogContent, Button, Box,
    Stack, DialogActions, Typography
} from '@mui/material'
import ReplyIcon from '@mui/icons-material/Reply';
import styles from './VR.module.css'

export default function VRexperienceManager({ setMode, vrConfig }) {



    return (<>

        {/* <img
            style={{
                top: '90vh',
                zIndex: 9, cursor: 'pointer', opacity: "0.6"
            }}
            className={styles.button}
            onClick={() => { setMode("AR") }}
            src="https://armeta-card-builder.appspot.com.storage.googleapis.com/manual/Ar.Shraddha/BackButton.png">
        </img> */}
        <Button
            variant='contained'
            startIcon={<ReplyIcon />}
            sx={{
                backgroundColor: 'black', color: 'white',
                borderRadius: 10,
                zIndex: 9, top: '80vh',
                opacity: 0.8
            }}
            className={styles.button}
            onClick={() => {
               /*  setMode("AR") */
                window.location.reload()
            }}
        >
            Go Back
        </Button>


        {/* {vrConfig.LeadGeneration ? (
            <>
                <div>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: 'black', color: 'white',
                            borderRadius: 10,
                            zIndex: 9, top: '80vh',
                            opacity: 0.8
                        }}
                        className={styles.button}
                        onClick={() => setMode("AR")}
                    >
                        {vrConfig.ButtonPrompt}
                    </Button>
                </div>

                <DialogPopup />
            </>
        ) : (null)} */}

        <a-scene
            cursor="rayOrigin: mouse">

            <a-entity raycaster="showLine: true; far: 100;  
                         lineOpacity: 0.5"></a-entity>
            <a-sky
                src={vrConfig.Image}
            ></a-sky>

        </a-scene>

    </>
    )
}


