
import React, { useState, useEffect, useRef } from "react";

import ExperienceManager from "./components/experienceManager";
/* import 'mind-ar/dist/mindar-image.prod.js';
import 'mind-ar/dist/mindar-image-aframe.prod.js'; */
import './App.css'


/* import mindarViewer from "./mindar-viewer"; */
import EventHandler from './components/Canvas/EventHandler'

import {
    Init
} from './components/firebase'

export default function UserName() {


    ////Game Char Control
    const jumping = useRef({
        jumping: false
    });

    var username = "PiyushDugge";
    const [GameState, setGameState] = useState({
        Start: false,
        Pasued: false,
        ResetSwitch: false,
        Over: false,
        inGame: false,
        HighScore: false
    })

    useEffect(() => {
        const init = Init()
        console.log(GameState.inGame)
    }, [GameState])


    return (<>
        <div className="App">
            {/*  <div id="example-scanning-overlay"
                className="hidden">
                <div className="inner">
                    <img src={"https://reality-reach-assets.storage.googleapis.com/ar-card/markers/Binary.png"} />
                    <div className="scanline"></div>
                </div>
            </div> */}
            <div className='container'>
                <ExperienceManager {...{      
                    username,jumping, GameState, setGameState
                }}
                />
                {GameState.inGame ?
                    (
                        <EventHandler
                            {...{
                                GameState,
                                setGameState,
                                jumping
                            }} />)
                    :
                    null}
                <video></video>
            </div>
        </div>
    </>
    )
}
