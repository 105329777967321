import { useState, useEffect, useRef } from "react"
import { CheckTileConfig, FetchIconTrayDetails, FetchUserFiles, GetProfileState } from "../functions/db-functions/profileFunctions"
import { auth, Init, signOut } from "../components/firebase"
import VRexperienceManager from "../components/VR/VRexperienceManager"
import { Stack, Dialog, DialogActions, DialogContent, Box, Button } from "@mui/material"
import { Call, Email, Instagram, LinkedIn, Website, WebsitePopup, WhatsApp } from "../functions/actionFunctions"

import { get, child, getDatabase, ref } from '../components/firebase'
import headerTile from '../assets/images/headerTile.png'
import dynamicTile from '../assets/images/dynamicTile.png'
import { PrototileIconStatic } from "../components/Prototile/PrototileIcon"
import { PdfTileIconStatic } from "../components/PdfTile/PdfTileIcon"
import { PdfPopup } from "../components/PdfTile/PdfPopup"

import styles from '../components/ComponentStyles/buttonStyles.module.css'
import ViewInArIcon from '@mui/icons-material/ViewInAr';

export default function StaticMode({username,profileHash,setMode}) {

    const [canvasInteraction, setCanvasInteraction] = useState(null)
    const [pageOneFiles, setPageOneFiles] =
        useState({
            HeaderTile: headerTile,
            DynamicTile: dynamicTile,
            Logo: null,
            PDF: null
        })

    const [websitePopup, setWebsitePopup] = useState(false)

    const [prototileState, setPrototileState] = useState({
        integrations: null,
        activeTile: null
    })

    const [pdfTileState, setPdfTileState] = useState({ activeTile: null })

    const [page, setPage] = useState("PageOne")

    const [vrConfig, setVrConfig] = useState({
        Complete: false,
        LeadGeneration: true,
        Image: null,
        ButtonPrompt: null,
        Email: null
    })

    const [iconTrayDetails, setIconTrayDetails] =
        useState({
            Format: "",
            One: "Undefined",
            Two: "Undefined",
            Three: "Undefined",
            Four: "Undefined",
            Five: "Undefined",
            Six: "Undefined",
            Call: "",
            Email: "",
            Website: "",
            LinkedIn: "",
            WhatsApp: "",
            Location: "",
            Instagram: ""
        })

     useEffect(() => {
        const init = Init()
        console.log(profileHash);
        const db = getDatabase()
        var dbRef = ref(db)

        get(child(dbRef, "vision/card/userNames/"+username)).then((snapshot) => {
            if (snapshot.exists()) {
                GetProfileState({
                    profileHash,
                    profileSnapshot: snapshot.val(),
                    setVrConfig,
                    setCanvasInteraction
                })
                FetchIconTrayDetails(snapshot.val(), setIconTrayDetails)
                if (profileHash) {
                    console.log("Profile Hash: " + profileHash);
                    FetchUserFiles(profileHash, setPageOneFiles)
                    CheckTileConfig({
                        profileHash, profileSnapshot: snapshot.val(),
                        setPageOneFiles, setPrototileState,
                        setPdfTileState
                    })
                }
            }
        })

         
           
    }, [profileHash]);

    function InvokeIconFunction(icon) {
        switch (icon) {
            case "Call": Call(iconTrayDetails.Call); break;
            case "Email": Email(iconTrayDetails.Email); break;
            case "Website": setWebsitePopup(true); break;
            case "LinkedIn": LinkedIn(iconTrayDetails.LinkedIn); break;
            case "WhatsApp": WhatsApp(iconTrayDetails.WhatsApp); break;
            case "Location":
            case "Instagram": Instagram(iconTrayDetails.Instagram); break;
        }
    }

    const bgImage = "https://cdn.discordapp.com/attachments/1062941458090442783/1075694819239800838/pexels-pixabay-219692.png"

    return (
        <div
            style={{
                backgroundImage: `url(${bgImage})`, backgroundPosition: 'top', minHeight: "100vh",
                backgroundColor: 'black'
            }}>
            {/* Body Images */}
            {iconTrayDetails ? (
                <WebsitePopup {...{
                    value: iconTrayDetails.Website,
                    websitePopup,
                    setWebsitePopup
                }} />) : null}

            <Box height="7vh"></Box>
            

            <Stack direction="column"
                alignItems="center"
                spacing={2}
                height='100%'
            >
                <img width="80%" height="80%" src={pageOneFiles.HeaderTile} />
                <img width="80%" src="https://reality-reach-assets.storage.googleapis.com/ar-card/markers/Binary.png" />
                <Box width='80%'>
                    <Stack direction='row' spacing={2}>
                        <Box width='50%'>
                            <PrototileIconStatic {...{
                                prototileState,
                                pageOneFiles, setPage, setMode
                            }} />
                        </Box>
                        <Box width='50%'>
                            <PdfTileIconStatic {...{
                                pdfTileState,
                                pageOneFiles,
                                setPage
                            }} />
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            <Box height="10vh"></Box>

            {/* Click overlay */}
            <Stack direction="column"
                justifyContent="center"
                width="100%"
                alignItems="center"
                spacing={1}
                style={{
                    position: 'fixed',
                    display: 'block',
                    width: '100%',
                    height: '50%',
                    top: 0,
                    left: 0,
                    right: 0,
                    /*  bottom: 0, */
                    /*  backgroundColor: 'rgba(0, 0, 0, 0.5)', */
                }}
            >
                <Box height="7vh"></Box>

                {/* 
                <a href="https://www.oculus.com/open_url/?url=https%3A%2F%2Fwww.meta.com">Open Meta.com on your Meta Quest headset</a>
                */}

                <Button
                

                    className={styles.button}
                    sx={{
                        backgroundColor: 'black', color: 'white',
                        borderRadius: 10,
                        zIndex: 10,
                        opacity: 0.8,
                        top: '60vh'
                    }}
                    variant='contained'
                    onClick={() => { //window.open('/', '_self'); 
                        setMode("AR") }}
                    startIcon={<ViewInArIcon />}>
                    View in AR</Button>


                <Box width="100vw" justifyContent='center' height="10vw" >
                    <Stack direction="row" justifyContent="space-between">
                        <Box height="10vh" width="35vw"
                            onClick={() => {
                                InvokeIconFunction(iconTrayDetails.One)
                            }}
                         sx={{backgroundColor: 'primary.dark'}}
                        ></Box>
                        <Box height="10vh" width="35vw"
                            onClick={() => { InvokeIconFunction(iconTrayDetails.Two) }}
                            /* sx={{
                            backgroundColor: 'primary.dark',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                opacity: [0.9, 0.8, 0.7],
                            },
                        }} */></Box>
                    </Stack>
                    <Box height="4vw"></Box>
                    <Stack direction="row" justifyContent='space-between'>
                        <Box height="10vh" width="35vw"
                            onClick={() => { InvokeIconFunction(iconTrayDetails.Three) }}
                        /* sx={{backgroundColor: 'primary.dark', }} */></Box>
                        <Box height="10vh" width="35vw"
                            onClick={() => { InvokeIconFunction(iconTrayDetails.Four) }}
                            sx={{ backgroundColor: 'primary.dark'}}></Box>
                    </Stack>

                </Box>

            </Stack>


            <PdfPopup {...{ page, pageOneFiles, setPage }} />
        </div >) 
}