import {
    getDatabase, ref, getStorage,
    get, child, strRef, getDownloadURL, update
} from "../../components/firebase";
import checkUserVrConfig from "../../components/VR/userVrConfig";
import axios from "axios";



export function CheckTileConfig(
    { profileHash, profileSnapshot,
        setPageOneFiles, setPrototileState,
        setPdfTileState }) {
    console.log("i esist here")
    if (profileSnapshot) {
        console.log("i esist")
        /*   setPrototile(snapshot.val().Prototile) */
        //setPrototileState({ ...prototileState, activeTile: snapshot.val().ProtoTile })
        setPrototileState(prevState => {
            return {
                ...prevState,
                activeTile: profileSnapshot.Prototile
            };
        });
        setPdfTileState(prevState => {
            return {
                ...prevState,
                activeTile: profileSnapshot.PdfTile
            }
        })
        console.log(profileSnapshot)

        // UpdatePrototile(snapshot.val().ProtoTile)
        // alert(snapshot.val().Prototile)
        if (profileSnapshot.Prototile == "Logo" || profileSnapshot.PdfTile == "Logo") {
            const storage = getStorage()
            getDownloadURL(strRef(storage, 'vision/users/' + profileHash + "/Images/Logo"))
                .then((url) => {
                    console.log(url)
                    setPageOneFiles(prevState => {
                        return {
                            ...prevState,
                            Logo: url
                        }
                    })
                })
                .catch((error) => {
                });
        }
    }
    else {
        /* alert('problem') */
    }

}



export async function FetchUserFiles(profileHash, setPageOneFiles) {
    const db = getDatabase()
    var dbRef = ref(db)
    console.log("Inside FetchUserFiles"+profileHash);

    async function getStorageAndSetProperty(fileArray, path, title) {
        const db = getDatabase()
        var dbRef = ref(db)
        const storage = getStorage()

        await getDownloadURL(strRef(storage, "vision/users/" + profileHash + path + title))
            .then((url) => {
                console.log(url)
                fileArray.push(url)
            })
            .catch((error) => {
                // Handle any errors
                /*  alert(error) */
                fileArray.push(null)
            });
    }


    var fileArray = []
    await getStorageAndSetProperty(fileArray, "/Images/", "HeaderTile")
    await getStorageAndSetProperty(fileArray, "/", "PDF")

    console.log(fileArray)

    /* let blob = await fetch(imageArray[0]).then(r => r.blob())
      .then(res => console.log(res)); */
    /*  console.log(blob) */

    setPageOneFiles(
        prevState => {
            return {
                ...prevState,
                HeaderTile: fileArray[0],
                PDF: fileArray[1]
            }
        })
}




export function FetchIconTrayDetails(profileSnapshot, setIconTrayDetails) {

    if (profileSnapshot) {
        if (profileSnapshot.IconTray) {
            console.log(profileSnapshot.IconTray)
            setIconTrayDetails({
                One: profileSnapshot.IconTray.One,
                Two: profileSnapshot.IconTray.Two,
                Three: profileSnapshot.IconTray.Three,
                Four: profileSnapshot.IconTray.Four,
                Call: profileSnapshot.IconTray.Call,
                Email: profileSnapshot.IconTray.Email,
                Website: profileSnapshot.IconTray.Website,
                LinkedIn: profileSnapshot.IconTray.LinkedIn,
                WhatsApp: profileSnapshot.IconTray.WhatsApp,
                Location: profileSnapshot.IconTray.Location,
                Instagram: profileSnapshot.IconTray.Instagram
            })
        }
    }
}

export async function GetProfileSnapshot({ username,
    profileHash,
    setProfileSnapshot, setVrConfig,
    setCanvasInteraction }) {
    const db = getDatabase()
    var dbRef = ref(db)

    get(child(dbRef, "vision/card/userNames/"+username )).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val())
            console.log("Fetching user files: "+ profileHash);
            setProfileSnapshot(snapshot.val())
            GetProfileState({
                profileHash,
                profileSnapshot: snapshot.val(),
                setVrConfig,
                setCanvasInteraction
            })
        }
        else {
            alert('Please create your profile before accessing the test experience')
        }
    }
    ).catch((error) => {
        alert(error)
    })
}

export async function GetProfileState({
    profileHash,
    profileSnapshot,
    setVrConfig,
    setCanvasInteraction }) {

    // console.log(snapshot.val())
    if (profileSnapshot) {

        checkUserVrConfig(
            profileHash, setVrConfig, profileSnapshot)

        if (profileSnapshot.Canvas) {
            setCanvasInteraction(profileSnapshot.Canvas)
        }
        else {
            alert('Profile is incomplete. Please contact owner.')
        }
    }
    else {
        alert('Please create your profile before accessing the test experience')
    }
}




export async function FetchServerSubscriptionState({ subId, profileHash, subscriptionState }) {
    var serverResponse
    try {
        console.log("Checking")
        serverResponse = await axios.post(process.env.REACT_APP_PAYMENT_SERVER + '/subscriptions/' + subId);
        //setPrompt({ type: 'success', message: data.message }) 
    } catch (error) {
        console.log(error)
    }
    const db = getDatabase()

    var currentDate = new Date()
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
    var year = currentDate.getFullYear()

    console.log(serverResponse)
    subscriptionState.current = serverResponse.data.response.status

    update(ref(db, "vision/card/userHashes/" + profileHash + "/SubscriptionDetails"), {
        SubscriptionState: serverResponse.data.response.status,
        LastChecked: month + "/" + day + "/" + year
    })
}