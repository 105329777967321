import { getDatabase, ref, update } from '../../firebase'

export default function updateHighScore(username,
    score, setHighScore,setGameState) {
    setHighScore(score.current)
    var db = getDatabase()

    update(ref(db, "vision/card/userNames/" + username), {
        HighScore: score.current
    })
    setGameState(prevState=>{
        return{
            ...prevState,
            HighScore:true
        }
    })
}