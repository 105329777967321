import {
    Dialog, DialogContent,
    Typography, Stack, Button
} from "@mui/material";
import PDF from "./Pdf";


export function PdfPopup({ page, pageOneFiles, setPage }) {

    const styles = theme => ({
        root: {
            margin: 0,
            padding: theme.spacing(3)
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(2),
            color: theme.palette.grey[500]
        }
    });
    const handleClose = () => {
        setPage("PageOne")
    }



    return pageOneFiles.PDF ? (<>

        <Dialog open={page == "PDF"} onClose={handleClose}
            fullWidth
            maxWidth='xl'
            sx={{
                height: '100vh',
                margin: 0,
                borderRadius: 40,
                '& .MuiDialogContent-root': {
                    padding: 0,
                },
                '& .MuiDialog-root': {
                    borderRadius: '10px',
                },
                '& .MuiPaper-root': {
                    borderRadius: 10
                },
            }}
            style={{
                zIndex: 99
            }}
        >

            <DialogContent>

                <Stack direction="column"
                    justifyContent="center"
                    alignItems="center" spacing={1}
                    style={{ height: '100vh' }} >
                    <iframe src={pageOneFiles.PDF}
                        title="testPdf"
                        style={{ height: '100vh' }}
                        width="100%" />
                    {/*  <iframe style={{ height: '100vh' }}>
                        <PDF />
                    </iframe> */}
                    {/* <PDF /> */}
                    <Button onClick={handleClose}
                        variant='contained'
                        style={{ position: 'fixed', top: '90%' }}
                        sx={{ size: 'small', backgroundColor: 'black', color: 'white', borderRadius: 10 }}
                    >
                        Close</Button>
                </Stack>


            </DialogContent>

        </Dialog>
    </>) : null
}

