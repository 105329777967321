
import { initializeApp } from "firebase/app";
/* import { getAnalytics } from "firebase/analytics"; */
//import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

import {
  getDatabase, ref, get,
  child, update
} from "firebase/database"

import {
  getAuth, onAuthStateChanged as authChange,
  signInAnonymously as signInAnon,
  setPersistence,
  browserSessionPersistence
} from "firebase/auth"

import {
  getStorage,
  ref as strRef,
  uploadBytesResumable,
  getDownloadURL
}
  from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCwPcJT1EDlyjGJmEfVwdjDPVmnWnx2xMk",
  authDomain: "reality-reach.firebaseapp.com",
  databaseURL: "https://reality-reach-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "reality-reach",
  storageBucket: "reality-reach.appspot.com",
  messagingSenderId: "934943777462",
  appId: "1:934943777462:web:fa47452298bb116936fc17",
  measurementId: "G-YVHX9X371Y"
};

export const Init = () => {
  var app = initializeApp(firebaseConfig)
  //eslint-disable-next-line
  //self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

/*   const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdD3_gkAAAAAN9RPeEmali1Rffc1c0JrwFIKxkf'),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  }); */
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const onAuthStateChanged = authChange
export const signInAnonymously = signInAnon


export { getDatabase, getStorage, getDownloadURL, strRef, get, ref, child, update }

setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    /* return signInWithEmailAndPassword(auth, email, password); */
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
  });

