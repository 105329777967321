
import { useState, useEffect, useRef } from 'react'
import {
    Init, sRef, ref, get, child, auth,
    getStorage, strRef,
    getDatabase, getDownloadURL
} from '../firebase'

import {
    Call, LinkedIn, Email, WhatsApp,
    Instagram, Website, WebsitePopup
} from '../../functions/actionFunctions'
import headerTile from '../../images/AR/headerTile.png'
import dynamicTile from '../../images/AR/dynamicTile.png'

import axios from 'axios'
import {
    Dialog, DialogContent, Button, Box,
    Stack, DialogActions, Typography
} from '@mui/material'
import { CheckPrototileConfiguration, CheckTileConfig, FetchIconTrayDetails, FetchUserFiles, FetchUserImages } from '../../functions/db-functions/profileFunctions'
import { PdfPopup } from '../PdfTile/PdfPopup'
import PrototileIconAR from '../Prototile/PrototileIcon'
import PdfTileIconAR from '../PdfTile/PdfTileIcon'
import ARcontactIcons from './ARcontactIcons'

export default function VisionAR({
    profileHash, profileSnapshot,
    uiVisible, setMode }) {

    const [fetched, setFetched] = useState(false)

    const [prototileState, setPrototileState] = useState({
        integrations: null,
        activeTile: null
    })

    const [pdfTileState, setPdfTileState] = useState({ activeTile: null })

    const [page, setPage] = useState("PageOne")

    const [pageOneFiles, setPageOneFiles] =
        useState({
            HeaderTile: headerTile,
            DynamicTile: dynamicTile,
            Logo: null,
            PDF: null
        })

    const [websitePopup, setWebsitePopup] = useState(null)

    const [iconTrayDetails, setIconTrayDetails] =
        useState({
            Format: "",
            One: "Undefined",
            Two: "Undefined",
            Three: "Undefined",
            Four: "Undefined",
            Five: "Undefined",
            Six: "Undefined",
            Call: null,
            Email: null,
            Website: null,
            LinkedIn: null,
            WhatsApp: null,
            Location: null,
            Instagram: null
        })

    useEffect(() => {
        const inti = Init()
        const db = getDatabase()
        if (!fetched && profileHash && profileSnapshot) {
            console.log("fetching")
            FetchUserFiles(profileHash, setPageOneFiles)
            FetchIconTrayDetails(profileSnapshot, setIconTrayDetails)
            CheckTileConfig({
                profileHash, profileSnapshot,
                setPageOneFiles, setPrototileState, setPdfTileState
            })
            console.log(pdfTileState.activeTile)
            setFetched(true)
        }
    }, [fetched, profileHash, profileSnapshot])


    /* useEffect(() => {
        if (pageOneFiles.HeaderTile) {
            let imageURL = pageOneFiles.HeaderTile

            let downloadedImg = new Image()
            downloadedImg.crossOrigin = "anonymous"
            downloadedImg.src = imageURL

            var header = document.getElementById('headerTile')
            header.crossOrigin = "anonymous"
            header.src = imageURL
        }
    }, [pageOneFiles]) */


    function getProperties(link) {
        return ("shader:gif;transparent:true;src:url(" + link + ");opacity:0.99;")
    }

    return (<>

        {pdfTileState ?
            pdfTileState.activeTile == "PDF" ?
                <PdfPopup {...{ page, pageOneFiles, setPage }} /> :
                (<></>) : null
        }

        {/* {prototileState.activeTile != "Logo" ?
            <ProtoPopup {...{ page, setPage }} /> :
            (<></>)} */}

        <a-entity id="PortfolioOverlay"
            visible={uiVisible}
            portfolioanimations
            opacity="0" scale="1 1 1"
            position="0 0 0">

            <a-plane
                crossOrigin="anonymous"
                src={pageOneFiles.HeaderTile}
                width="1"
                class="pageOne ui"
                shader="flat"

                height="0.7"
                opacity="0.99"
                position="0 0.75 0"
            ></a-plane>

            <ARcontactIcons {...{ iconTrayDetails }} />

            {prototileState ?
                prototileState.activeTile ?
                    (<PrototileIconAR {...{
                        prototileState,
                        pageOneFiles,
                        setPage,
                        setMode
                    }} />)
                    :
                    (<a-plane
                        src={pageOneFiles.DynamicTile}
                        width="0.5"
                        class="clickable button pageOne ui"
                        shader="flat"
                        height="0.5"
                        opacity="0.99"
                        scale="1 1 1"
                        position="-0.265 -0.6 0"
                    />) : null}

            {pdfTileState ?
                pdfTileState.activeTile ?
                    (<PdfTileIconAR {...{
                        pdfTileState,
                        pageOneFiles,
                        setPage
                    }} />)
                    :
                    (<></>) : null}

        </a-entity>

    </>
    )
}



