import React, { useState } from 'react';

/* import 'aframe'; */

import './App.css';
import Home from './Home';

import { Routes, Route, Link } from "react-router-dom";

import MindARViewer from './mindar-viewer';

import UserName from './UserName';
import StaticProfile from './components/Static/StaticProfile';

function App() {
  return (
    <Routes className="App">
        <Route path="/" element={<Home />} />
        <Route path="/:username" element={
            <UserName />
        } />
        <Route path="/static" element={
            <StaticProfile />
        } />
      </Routes>

   /*  <div className="App">

   

    </div> */
  );
}

export default App;
