import { Stack } from "@mui/material"



export function PrototileIconStatic({ prototileState,
    pageOneFiles, setPage, setMode }) {

    var IntegrationHubPng = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/integration_hub/IntegrationHub.png'
    var VRPng1 = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/vr/VR.png'
    var VRPng = 'https://cdn.discordapp.com/attachments/1069195643211481138/1074528644497678346/TILE_360Mode.png'
    var VerticalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/vertical.gif'
    var HorizontalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/horizontal.gif'

    var DualHorizontalGif = "https://cdn.discordapp.com/attachments/1069195643211481138/1073508780932878347/Horizon2GIF.gif"

    function PrototilePng() {

        return prototileState.activeTile == "IntegrationHub" ?
            (<>
                <img
                    src={IntegrationHubPng}
                    width="0.5"
                    height="0.3785"
                    onClick={() => setPage("ProtoTile")}
                />
                <img
                    width="100"
                    height="25"
                    src={VerticalGif}
                />
            </>
            ) : prototileState.activeTile == "VR" ?
                (<>
                    <Stack spacing={2}>
                        <img
                            src={VRPng}
                            width="100%"
                            height="100%"
                            onClick={() => { setMode("VR") }}
                        />

                        <img
                            src={DualHorizontalGif}
                            height="100%"
                            width="100%"
                        />
                    </Stack>


                </>
                ) :
                (<></>)

    }


    function Logo() {
        return pageOneFiles.Logo ?
            <img
                src={pageOneFiles.Logo}
                width='100%'
                height='100%'
            /> : (
                <img
                    src={pageOneFiles.DynamicTile}
                    width='100%'
                    height='100%'
                />)
    }

    return (prototileState.activeTile == "VR" ||
        prototileState.activeTile == "IntegrationHub") ? (

        <PrototilePng />

    ) :
        (<Logo />)
}




export default function PrototileIconAR({ prototileState,
    pageOneFiles, setPage, setMode }) {

    var IntegrationHubPng = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/integration_hub/IntegrationHub.png'
    var VRPng1 = 'https://reality-reach-assets.storage.googleapis.com/ar-card/images/prototile/vr/VR.png'
    var VRPng = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/360/360Tile.png'
    var VRPngDiscord = 'https://cdn.discordapp.com/attachments/1069195643211481138/1074528644497678346/TILE_360Mode.png'
    var VerticalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/accents/Vertical.gif'
    var HorizontalGif = 'https://reality-reach-assets.storage.googleapis.com/vision/general-assets/accents/Horizontal.gif'

    function PrototilePng() {

        return prototileState.activeTile == "IntegrationHub" ?
            (<>
                <a-plane
                    src={IntegrationHubPng}
                    width="0.5"
                    class="clickable button pageOne ui"
                    shader="flat"
                    height="0.3785"
                    opacity="0.99"
                    onClick={() => setPage("ProtoTile")}
                    scale="1 1 1"
                    position="-0.265 -0.55 0" />
               {/*  <a-entity
                    geometry="primitive:plane;
              width:0.5;
              height:0.125;"
                    material="shader:gif;transparent:true;
             src:url(https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/vertical.gif);
             opacity:0.99;"
                    class="pageOne ui"

                    opacity="0.99"
                    scale="1 1 1"
                    position="-0.285 -0.6 0"
                ></a-entity> */}
            </>
            ) : prototileState.activeTile == "VR" ?
                (<>
                    <a-plane
                        src={VRPng}
                        width="0.5"
                        class="clickable button pageOne ui"
                      /*   shader="flat" */
                        height="0.3785"
                        opacity="0.99"
                        scale="1 1 1"
                        onClick={() => { setMode("VR") }}
                        position="-0.265 -0.55 0" />

                   {/*  <a-entity
                        geometry="primitive:plane;
              width:0.5;
              height:0.046;"
                        material="shader:gif;transparent:true;
             src:url(https://reality-reach-assets.storage.googleapis.com/vision/general-assets/accents/Horizontal.gif);opacity:0.99;"
                        class="pageOne ui"
                        scale="1 1 1"
                        position="-0.265 -0.79 0"
                    ></a-entity>

                    <a-entity
                        geometry="primitive:plane;
              width:0.5;
              height:0.046;"
                        material="shader:gif;transparent:true;
             src:url(https://reality-reach-assets.storage.googleapis.com/vision/general-assets/tiles/horizontal.gif);opacity:0.99;"
                        class="pageOne ui"
                        scale="1 1 1"
                        position="-0.265 -0.83 0"
                    ></a-entity> */}
                </>
                ) :
                (<></>)

    }


    function Logo() {
        return pageOneFiles.Logo ?
            <a-plane
                src={pageOneFiles.Logo}
                width="0.5"
                class="clickable button pageOne ui"
                shader="flat"
                height="0.5"
                opacity="0.99"
                scale="1 1 1"
                position="-0.265 -0.6 0"
            /> : (
                <a-plane
                    src={pageOneFiles.DynamicTile}
                    width="0.5"
                    class="clickable button pageOne ui"
                    shader="flat"
                    height="0.5"
                    opacity="0.99"
                    scale="1 1 1"
                    position="-0.265 -0.6 0"
                />)
    }

    return (prototileState.activeTile == "VR" ||
        prototileState.activeTile == "IntegrationHub") ? (

        <PrototilePng />

    ) :
        (<Logo />)
}

