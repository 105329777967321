

export function jump(GameState, setGameState, jumping) {
    const character = document.getElementById("char")
    const obstacle = document.getElementById("obstacle");
    const obstaclePair = document.getElementById("obstacle2");

    console.log("jump")
    if (jumping.current.jumping == false) {
        console.log("jump passed fail test")
        jumping.current.jumping = true
        character.emit("jumpRotate", null);
        character.emit("jumpScale", null);
    }

    if (GameState.Over) {
        setGameState(prevState => {
            return { ...prevState, Over: false, HighScore: false }
        })
        character.object3D.position.y = 0
        obstacle.object3D.position.x = 0.35
        obstaclePair.object3D.position.x = 0.35
        obstacle.play()
        character.play()
    }
}

export function DoNothing() {

}