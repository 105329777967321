
import { useEffect, useRef, useState } from 'react'
import {
    Init,
    auth, get, child, ref, getDatabase,
    getStorage, getDownloadURL
} from './firebase';
import ARexperienceManager from './AR/ARexperienceManager'
import ScanningOverlay from './ScanningOverlay/ScanningOverlay'
import VRexperienceManager from './VR/VRexperienceManager'
import { GetProfileSnapshot } from '../functions/db-functions/profileFunctions';
import StaticMode from '../modes/Static';

export default function ExperienceManager({
    username, jumping, GameState, setGameState }) {

    const [tabHasFocus, setTabHasFocus] = useState(true);

    const sceneRef = useRef(null)

    // const arSystem = useRef(null)

    const [arSystem, setArSystem] = useState(null)
    const db = getDatabase()
    const dbRef = ref(db)

    const [profileHash, setProfileHash] = useState(null)

    const [aframeComponentsInitialized, setAframeComponentsInitialized] = useState({
        General: false,
        Canvas: false
    })

    const [profileSnapshot, setProfileSnapshot] = useState(null)
    const [uiVisible, setUiVisible] = useState(true)

    const [canvasInteraction, setCanvasInteraction] = useState(null)

    const [mode, setMode] = useState("Static")

    const [vrConfig, setVrConfig] = useState({
        Complete: false,
        LeadGeneration: true,
        Image: null,
        ButtonPrompt: null,
        Email: null
    })


    function StringSplitter() {
        //http://localhost:3001/?s=dada
        var keyArray = window.location.search.split('=')
        //var keyArray2 = window.location.search
        var key = keyArray[1]
        console.log(key)
        return key
    }

    useEffect(() => {
        const init = Init()

        setProfileHash(StringSplitter())
        console.log("sess");
    
        GetProfileSnapshot({
            username, profileHash: StringSplitter(),
            setProfileSnapshot,
            setVrConfig,
            setCanvasInteraction
        })
        
    }, []);


    useEffect(() => {
        const AFRAME = window.AFRAME
        AFRAME.registerComponent("portfolioanimations", {
            init: function () {
                console.log("animation controller registered")
                this.el.setAttribute("animation", {
                    property: "position",
                    from: "0 0 0",
                    to: "0 0 -0.3",
                    dur: 500,
                    'startEvents': 'startGame',
                });

                this.el.setAttribute("animation__3", {
                    property: "position",
                    from: "0 0 -0.3",
                    to: "0 0 0",
                    dur: 500,
                    'startEvents': 'endGame',
                });
                setAframeComponentsInitialized(prevState => {
                    return { ...prevState, General: true }
                })
            }
        })
    }, []);

    return mode == "AR" ?
        (<div
            style={{
                height: '100vh',
                width: '100%',
            }}
        >
            
            {/*  <p>full tank</p> */}
           {/*  <ScanningOverlay {...{ username, profileSnapshot }} /> */}

         <ARexperienceManager
                {...{
                    username, profileHash, profileSnapshot,

                    aframeComponentsInitialized, setAframeComponentsInitialized,

                    GameState, setGameState, jumping,

                    canvasInteraction, sceneRef, tabHasFocus, uiVisible, setUiVisible, setMode
                }}
            />
        </div>
        ) :
        mode == "VR" ?
            (<>
                <VRexperienceManager {...{
                    setMode, vrConfig
                }} />
            </>) :
        mode == "Static" ?
            (<>
                <StaticMode {...{
                    username, profileHash:profileHash,setMode
                }} />
            </>)
            : (<></>)
}