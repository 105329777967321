
import { set, ref, update, getDatabase, getStorage, sRef, strRef, getDownloadURL } from "../firebase";
import { useEffect } from "react";

/**
 * 
 * @param {*} uid 
 * @param {*} profileHash
 * @param { "vision/adminPortal/admins/" + uid + "/users/" + profileHash + "/UserDetails"} snapshot  
 * Checks database for configuration set by the user for VR mode. 
 * Also sets Vr image to be consumed by the VR experience manager.  
 */

export default function checkUserVrConfig(profileHash, setVrConfig, snapshot) {


    if (snapshot) {
        if (snapshot.Prototile == "VR") {
            /* setVrConfig(prevState => {
                return {
                    ...prevState,
                    Complete: snapshot.VR.Complete,
                    LeadGeneration: snapshot.VR.LeadGeneration,
                    Email: snapshot.VR.Email,
                    ButtonPrompt: snapshot.VR.ButtonPrompt
                }
            }) */

            /* if (snapshot.VR.Complete) {
              

            } */
            getStorageAndSetProperty(profileHash, setVrConfig)
        }
    }


}

async function getStorageAndSetProperty(profileHash, setVrConfig) {
    const db = getDatabase()
    var dbRef = ref(db)
    const storage = getStorage()
    await getDownloadURL(strRef(storage, "vision/users/" + profileHash + "/Images/VRImage"))
        .then((url) => {
            setVrConfig(prevState => {
                return {
                    ...prevState,
                    Image: url
                }
            })
        })
        .catch((error) => {

        });
}
